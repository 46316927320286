<template>
  <div class="container mt-[250px]">
    <section class="hero is-medium is-link">
      <div class="hero-body my-hero-vue-3-apps-studio-background">
        <box is-pulled-right>
          <div class="title has-text-white">Vue 3 Apps</div>
          <div class="subtitle">
            Utilizes my custom API proxy server with MongoDB.
          </div>
        </box>
      </div>
    </section>
    <section class="section">
      <div class="columns">
        <div class="column"></div>
        <div class="column">
          <div class="card">
            <div class="card-content">
              <p class="title">
                <i class="fas fa-umbrella fa-1x"></i>
              </p>
              <p class="subtitle">Weather API</p>
              <input
                v-model="city"
                class="input is-small"
                type="text"
                placeholder="Type A City"
              />
              <!-- <p v-if="stateMyWeatherForCity() !== 'nothing'">{{ stateMyWeatherForCity() }}</p> -->
              <p v-if="stateMyWeatherForCity() !== 'nothing'">
                Current weather for the city of
                {{ stateMyWeatherForCity().name }}
              </p>
              <p v-if="stateMyWeatherForCity() !== 'nothing'">
                is {{ stateMyWeatherForCity().main.temp }} degrees.
              </p>
              <section v-if="stateMyWeatherForCity() !== 'nothing'">
                <h2>Temp Graph in Development</h2>
                <!-- <BarChart /> -->
              </section>
            </div>
            <footer class="card-footer">
              <p class="card-footer-item">
                <span @click="clickToGetWeather()"> Run Vue 3 </span>
              </p>
              <p class="card-footer-item">
                <span class="strike-me">React Link</span>
              </p>
            </footer>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="card-content">
              <p class="title">
                <i class="fas fa-calculator fa-1x"></i>
              </p>
              <p class="subtitle">Basic Calculator</p>
            </div>
            <footer class="card-footer">
              <p class="card-footer-item">
                <span class="strike-me"> Vue 3 </span>
              </p>
              <p class="card-footer-item">
                <span class="strike-me">React Link</span>
              </p>
            </footer>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="card-content">
              <p class="title">
                <i class="fas fa-money-check fa-1x"></i>
              </p>
              <p class="subtitle">Stripe Process API Example</p>
            </div>
            <footer class="card-footer">
              <p class="card-footer-item">
                <button
                  class="px-4 py-2 text-white bg-blue-500 rounded-md shadow-md hover:bg-blue-600 focus:outline-none"
                  @click="postWeddingInquiryClick()"
                >
                  Post Wedding Inquiry
                </button>
                <span> </span>
                <span @click="allEmployeesWhatBackgroundDataCall()"> B</span>
                <span class="strike-me"> Vue 3 </span>
                <input
                  v-model="fullName"
                  type="text"
                  class="px-4 py-2 border border-gray-300 rounded-l focus:outline-none focus:ring focus:border-blue-500"
                  placeholder="Full Name"
                />
                <input
                  v-model="email"
                  type="text"
                  class="px-4 py-2 border border-gray-300 rounded-r focus:outline-none focus:ring focus:border-blue-500"
                  placeholder="Email"
                />
                <input
                  v-model="note"
                  type="text"
                  class="px-4 py-2 border border-gray-300 rounded-r focus:outline-none focus:ring focus:border-blue-500"
                  placeholder="Note"
                />
              </p>
              <p class="card-footer-item">
                <span class="strike-me">React Link</span>
              </p>
            </footer>
          </div>
        </div>
        <div class="column">
          <div class="card">
            <div class="card-content">
              <p class="title">
                <i class="fas fa-list fa-1x"></i>
              </p>
              <p class="subtitle">Todo App</p>
            </div>
            <footer class="card-footer">
              <p class="card-footer-item">
                <span class="strike-me"> Vue 3 </span>
              </p>
              <p class="card-footer-item">
                <span class="strike-me" @click="clickToGetData()"
                  >React Link</span
                >
                <!-- {{myPageData}} and {{fooID}} -->

                <!-- <button v-on:click="clickToGetData()">X</button> -->
                <!-- <button v-on:click="fetchData()">click here</button> -->
                <!-- <ul>
                  <li :key="post" v-for="post of myPageData">
                    <p></p>
                  </li>
                  </ul> -->
              </p>
              <!-- <p>Call API and show data here</p> -->
              <p v-if="proxyServerIsUpTest().bigtester !== 'nothing'">
                {{ proxyServerIsUpTest().bigtester }}
              </p>
            </footer>
          </div>
        </div>
        <div class="column">
          <!-- <p v-if="stateMyWeatherForCity() !== 'nothing'">{{ stateMyWeatherForCity() }}</p> -->
        </div>
      </div>
    </section>
    <section class="section">
      <div class="columns">
        <div class="column"></div>
        <div class="column">
          <div class="card">
            <div class="card-content">
              <p class="title">
                <i class="fas fa-database fa-1x"></i>
              </p>
              <p class="subtitle">Heroku Time</p>
            </div>
            <footer class="card-footer">
              <p class="card-footer-item">
                <span class="strike-me"> React </span>
              </p>
              <p class="card-footer-item">
                <span @click="getTestDataFromHerokuServer()">Vue 3</span>
              </p>
              <p>
                {{ herokuTestData() }}
              </p>
            </footer>
          </div>
        </div>
        <!-- <div class="column">
            <div class="card">
              <div class="card-content">
                <p class="title">
                  <i class="fas fa-database fa-1x"></i>
                </p>
                <p class="subtitle">Employees</p>
              </div>
              <footer class="card-footer">
                <p class="card-footer-item">
                  <span class="strike-me"> React </span>
                </p>
                <p class="card-footer-item">
                  <span v-on:click="allEmployeesDataCall()">Vue 3</span>
                </p>
                <p>
                  {{ allEmployees() }}
                </p>
              </footer>
            </div>
          </div> -->
        <div class="column">
          <p class="card-footer-item">
            <!-- <span v-on:click="allEmployeesDataCall()">.</span> -->
          </p>
          <p>
            {{ allEmployees() }}
          </p>
        </div>
        <div class="column"></div>
        <div class="column"></div>
      </div>
    </section>
  </div>
</template>

<script>
import { ref, toRefs, reactive } from "@vue/reactivity";
import { onMounted } from "vue";

// import { Test } from "../types/TestTypes";
// import { getTestService } from "../services/TestService";

import TestStore from "../store/TestStore";

// vue chart.js
// import BarChart from "./BarChart";

export default {
  name: "VueThreeApps",
  props: {
    fakeProp: {
      type: String,
      required: false,
    },
    msg: String,
  },
  //   components: { BarChart },
  setup() {
    // DATA
    const fooID = ref(42); // a 'ref' wraps it in a object to utilize javascript making it a varible that is 'by value' to 'by reference'
    const fooMyPageData = ref({ test: 42 });
    const city = ref();

    const fullName = ref("");
    const email = ref("");
    const note = ref("");

    const state = reactive({
      sectionHeaders: {
        aboutMe: "About Me",
        myTeachers: "My Teachers",
      },
    });

    // COMPUTED
    const {
      getters: {
        proxyServerIsUpTest,
        herokuTestData,
        stateMyWeatherForCity,
        allEmployees,
        allEmployeesWhatBackground,
      },
    } = TestStore;

    // METHODS
    // const { mutations: { SET_PROXY_SERVER_IS_UP_TEST } } = TestStore
    const {
      actions: {
        fetchFromProxyServerTest,
        fetchHerokuTestData,
        fetchAllEmployees,
        fetchAllEmployeesWhatBackground,
        fetchWeatherMapOrgDataForCity,
        postWeddingInquiry,
      },
    } = TestStore;

    function clickToGetData() {
      fetchFromProxyServerTest();
    }

    function getTestDataFromHerokuServer() {
      fetchHerokuTestData();
    }

    function allEmployeesDataCall() {
      fetchAllEmployees();
    }

    function allEmployeesWhatBackgroundDataCall() {
      fetchAllEmployeesWhatBackground();
    }

    function postWeddingInquiryClick() {
      let passedWeddingValues = {
        fullName: fullName.value,
        email: email.value,
        note: note.value,
      };
      postWeddingInquiry(passedWeddingValues);
    }

    function clickToGetWeather() {
      fetchWeatherMapOrgDataForCity(city.value);
    }

    // LIFE-CYCLE HOOKS
    // onMounted(getCoolData);
    onMounted(async () => {
      // fetchFromProxyServerTest()
    });

    // onMounted(() => {
    //   // fetchData();
    // });

    // Expose these variables to the template
    return {
      fooID,
      fooMyPageData,
      city,
      clickToGetData,
      getTestDataFromHerokuServer,
      allEmployeesDataCall,
      allEmployeesWhatBackgroundDataCall,
      postWeddingInquiryClick,
      clickToGetWeather,
      proxyServerIsUpTest,
      herokuTestData,
      allEmployees,
      allEmployeesWhatBackground,
      stateMyWeatherForCity,
      fullName,
      email,
      note,
      ...toRefs(state),
    };
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.blog-main {
  margin-top: 50px;
}

.cj-main-nav {
  background-color: rgba(0, 0, 0, 0.5) !important;
}

.cj-app-subtitle {
  font-size: 13px;
}

.strike-me {
  text-decoration: line-through;
}
</style>
