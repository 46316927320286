<template>
  <div class="footer">
    <div class="container flex flex-col mx-auto">
      <div
        class="extra-column-links h-[250px] md:h-[200px] grid grid-cols-3 gap-12 mt-6"
      >
        <div></div>
        <div>
          <!-- <h3
            class="useful-link-header text-white border-t-[1.5px] border-lime-400 text-left mt-[25px]"
          >
            USEFUL LINKS
          </h3> -->
          <div class="mt-[15px]">
            <div class="text-white">CELLO LINKS</div>
            <div href="https://imslp.org/">
              <a href="https://imslp.org/" target="_blank">IMSLP</a>
            </div>
            <div>
              <a href="https://www.sharmusic.com/" target="_blank">Sharmusic</a>
            </div>
            <div>
              <a href="https://www.cello.org/" target="_blank">www.cello.org</a>
            </div>
            <div>
              <a href="https://www.whlee.com/" target="_blank">W.H. Lee</a>
            </div>
          </div>
        </div>
        <!-- <div> -->
        <!-- <div
            class="technologies-header text-white border-t-[1.5px] border-yellow-400 text-left mt-[25px]"
          > -->
        <!-- <div class="mt-[15px]"> -->
        <!-- <div class="text-white">TECH</div> -->
        <!-- <div> -->
        <!-- Vue -->
        <!-- <i class="ml-1 fab fa-vuejs fa-sm cursor-pointer"></i> -->
        <!-- </div> -->
        <!-- <div> -->
        <!-- Express -->
        <!-- <i class="ml-1 fab fa-node-js fa-sm cursor-pointer"></i> -->
        <!-- </div> -->
        <!-- <div>
              Spring Boot -->
        <!-- <i class="ml-1 fas fa-seedling fa-sm cursor-pointer"></i> -->
        <!-- </div> -->
        <!-- <div> -->
        <!-- PostgreSQL -->
        <!-- <i class="ml-1 fab fa-kaggle fa-sm cursor-pointer"></i> -->
        <!-- </div> -->
        <!-- </div> -->
        <!-- </div> -->
        <div></div>
      </div>
      <div class="flex justify-center items-center justify-end mb-2">
        <div class="copy-right">© 2024 Cello Journeys Ltd</div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterComp",
  components: {},
  props: {
    msg: String,
  },
  setup(props, context) {
    const foo = 123456;
    return { foo };
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer {
  font-size: 12px;
  color: #999;
  background-color: #313131;
  font-family: "Montserrat", "sans-serif";
  .extra-column-links {
  }

  .useful-link-header {
    font-family: "Helvetica Neue", "Avenir", Helvetica, Arial, sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.8;
  }
  .copy-right {
    color: #999;
    font-family: "Montserrat", "sans-serif";
    font-size: 12px;
  }
}
</style>
