import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cello-studio" }
const _hoisted_2 = { class: "cello-studio-main-container mt-[200px] mb-[200px]" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_music_player = _resolveComponent("music-player")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_music_player, {
        class: "m-auto",
        msg: 'hello'
      })
    ])
  ]))
}