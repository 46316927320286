<template>
  <nav :style="{ backgroundColor: headerBackgroundColor }">
    <div class="nav-main-container flex justify-center">
      <div class="nav-link-text hover:text-amber-500 cursor-pointer">
        <a
          class="no-underline font-normal"
          @click="scrollToAboutMe"
          href="/#about-me"
        >
          <span
            class="hidden md:block"
            @mouseover="startAnimation"
            @mouseleave="resetAnimation"
            >About Me</span
          >
        </a>
      </div>
      <div class="nav-link-text hover:text-amber-500 cursor-pointer">
        <router-link to="/repertoire">
          <span
            class="hidden md:block text-visited font-normal"
            @mouseover="startAnimation"
            @mouseleave="resetAnimation"
            >Repertoire</span
          >
        </router-link>
      </div>
      <div class="main-site-icon hidden lg:block">
        <router-link to="/" class="font-bold"
          ><span class="letter-c text-amber-500">C</span>ello
          <span class="letter-j text-amber-500">J</span>ourneys</router-link
        >
      </div>
      <div class="main-site-icon lg:hidden">
        <router-link to="/" class="font-bold"
          ><span class="letter-c text-amber-500">C</span>
          <span class="letter-j text-amber-500">J</span></router-link
        >
      </div>
      <div class="nav-link-text hover:text-amber-500 cursor-pointer">
        <router-link to="/cello-studio">
          <span
            class="hidden md:block text-visited font-normal"
            @mouseover="startAnimation"
            @mouseleave="resetAnimation"
            >Recordings</span
          >
        </router-link>
      </div>
      <div class="nav-link-text hover:text-amber-500 cursor-pointer">
        <router-link to="/hire-me">
          <span
            class="hidden md:block text-visited font-normal"
            @mouseover="startAnimation"
            @mouseleave="resetAnimation"
            >Hire Me</span
          >
        </router-link>
      </div>
      <div
        class="md:hidden flex items-center pr-[18px] md:pr[0px] absolute top-[62px] right-[20px]"
      >
        <button
          type="button"
          class="text-gray-400 hover:text-white focus:outline-none focus:text-white"
          @click="toggleMobileMenu"
        >
          <i class="image-play-icon fas fa-bars fa-lg"></i>
        </button>
      </div>
    </div>
    <!-- Mobile menu -->
    <div
      :class="{ block: mobileMenuOpen, hidden: !mobileMenuOpen }"
      class="md:hidden fullscreen fullscreen-container bg-black bg-opacity-50"
    >
      <div class="flex flex-col sm:px-3 mt-4 text-white">
        <div class="float-right">
          <i
            @click="toggleMobileMenu"
            class="fas fa-times fa-lg cursor-pointer float-right pr-6 pt-4"
          ></i>
        </div>
        <a class="mt-12 text-2xl" @click="scrollToAboutMe" href="/#about-me">
          <span
            class="text-white text-visited font-normal mt-[20px] pt-[20px]"
            @mouseover="startAnimation"
            @mouseleave="resetAnimation"
            @click="toggleMobileMenu"
            >About Me</span
          >
        </a>
        <router-link to="/repertoire">
          <div class="mt-12 text-2xl" @click="toggleMobileMenu">
            <span
              class="text-white text-visited font-normal"
              @mouseover="startAnimation"
              @mouseleave="resetAnimation"
              >Reportoire</span
            >
          </div>
        </router-link>
        <router-link to="/cello-studio">
          <div class="mt-12 text-2xl" @click="toggleMobileMenu">
            <span
              class="text-white text-visited font-normal"
              @mouseover="startAnimation"
              @mouseleave="resetAnimation"
              >Recordings</span
            >
          </div>
        </router-link>
        <router-link to="/hire-me">
          <div class="mt-12 text-2xl" @click="toggleMobileMenu">
            <span
              class="text-white text-visited font-normal"
              @mouseover="startAnimation"
              @mouseleave="resetAnimation"
              >Hire Me</span
            >
          </div>
        </router-link>
      </div>
    </div>
  </nav>
  <router-view />
  <footer-comp></footer-comp>
</template>

<script>
import {
  computed,
  defineComponent,
  ref,
  watch,
  onMounted,
  onUnmounted,
  nextTick,
} from "vue";

import FooterComp from "@/components/Footer.vue";

export default defineComponent({
  name: "App",
  components: { FooterComp },
  props: {},
  setup(props, context) {
    // DATA
    const mobileMenuOpen = ref(false);

    // METHODS
    const toggleMobileMenu = () => {
      mobileMenuOpen.value = !mobileMenuOpen.value;
    };

    const startAnimation = (event) => {
      event.target.classList.add("underline-animation");
    };

    const resetAnimation = (event) => {
      event.target.classList.remove("underline-animation");
    };

    const scrollToAboutMe = () => {
      mobileMenuOpen.value = !mobileMenuOpen.value;
      window.location.hash = "about-me";
      // const targetElement = document.querySelector("#about-me");
      const targetElement = document.getElementById("about-me");

      if (targetElement) {
        // Scroll to the target element smoothly
        targetElement.scrollIntoView({
          block: "start",
        });
      }
    };

    // xxx

    const headerBackgroundColor = ref("rgba(0, 0, 0, 0)"); // Transparent black

    const updateHeaderBackground = () => {
      const scrollY = window.scrollY;
      if (scrollY > 100) {
        headerBackgroundColor.value = "rgba(0, 0, 0, 0.05)"; // Semi-transparent black
      } else {
        headerBackgroundColor.value = "rgba(0, 0, 0, 0)"; // Transparent black
      }
    };

    onMounted(() => {
      window.addEventListener("scroll", updateHeaderBackground);
    });

    onUnmounted(() => {
      window.removeEventListener("scroll", updateHeaderBackground);
    });

    return {
      mobileMenuOpen,
      toggleMobileMenu,
      startAnimation,
      resetAnimation,
      headerBackgroundColor,
      scrollToAboutMe,
    };
  },
});
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Monsieur+La+Doulaise&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Carlito&family=IM+Fell+DW+Pica:ital@0;1&family=Monsieur+La+Doulaise&family=MonteCarlo&family=Oleo+Script+Swash+Caps&family=Poltawski+Nowy:ital,wght@1,500&family=Roboto:ital,wght@0,300;0,400;1,100;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Monsieur+La+Doulaise&family=Tangerine&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arapey&family=Carlito&family=IM+Fell+DW+Pica:ital@0;1&family=Monsieur+La+Doulaise&family=MonteCarlo&family=Oleo+Script+Swash+Caps&family=Poltawski+Nowy:ital,wght@1,500&family=Roboto:ital,wght@0,300;0,400;1,100;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Arapey&family=Carlito&family=IM+Fell+DW+Pica:ital@0;1&family=Monsieur+La+Doulaise&family=MonteCarlo&family=Montserrat&family=Oleo+Script+Swash+Caps&family=Poltawski+Nowy:ital,wght@1,500&family=Roboto:ital,wght@0,300;0,400;1,100;1,300;1,400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gelasio&display=swap");

#app {
  font-family: "Poppins", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.fullscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Add a semi-transparent background color */
}

nav {
  padding: 30px 30px 40px 30px;
  // background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  width: 100%;

  .nav-link-text {
    font-family: "Arapey", "Poppins", Avenir, Helvetica, Arial;
    font-size: 14px;
    padding: 50px 20px 0px 20px;
  }

  .main-site-icon {
    font-family: "MonteCarlo", "Monsieur La Doulaise", "Poppins", sans-serif;
    font-size: 52px;
    color: #999;

    padding-left: 60px;
    padding-right: 60px;

    .dedication-shinji {
      font-size: 18px;
    }
  }

  a {
    font-weight: bold;
  }

  .underline-animation {
    position: relative;
  }

  .underline-animation::after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #f59e0b; /* Replace with your desired color */
    animation: underline 0.3s forwards;
  }

  @keyframes underline {
    from {
      transform: scaleX(0);
    }
    to {
      transform: scaleX(1);
    }
  }
}
</style>
