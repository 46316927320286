// import { Product } from '@/types/ProductTypes';
// import { mapPairs } from 'core-js/core/dict';

// export default {
//   getProduct,
// }

// export function getProduct(influencerGuid: UUID): Promise<Product[]> {
//   return mpi.get(`/products?influencerId=${influencerGuid}`).then(({ data }) => data);
// }

import { ProxyServerTestType } from "../types/TestTypes";

export default {
  getTestService,
  getMicroServiceHelloWorldService,
  getWeatherMapOrgDataForCityService,
  getMicroServiceAllEmployeesService,
  getExpressAllEmployeesService,
};

// export function getAnotherCall(): Promise<ProxyServerTestType> {
//   return fetch("http://localhost:9000/.netlify/functions/api", {
//   // return fetch("https://www.whatbackground.com/.netlify/functions/api", {
//     // const data = await fetch("https://www.whatbackground.com/.netlify/functions/api", {
//     method: "get",
//     headers: {
//       "content-type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//     },
//   }).then((body) => body)
//     .then((res) => {
//       return res.json();
//     })
// }

async function getTestBaseUrl() {
  return await fetch("http://localhost:9000/.netlify/functions/api/abc", {
    // return await fetch(
    //   "https://www.whatbackground.com/.netlify/functions/api/abc",
    // {
    // const data = await fetch("https://www.whatbackground.com/.netlify/functions/api", {
    method: "get",
    headers: {
      "content-type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((body) => body)
    .then((res) => {
      return res.json();
    });
}

export async function getTestService(): Promise<ProxyServerTestType> {
  const basicTestData = await getTestBaseUrl();
  return basicTestData;
}

async function getMicroServiceHelloWorldData() {
  return await fetch(
    // "http://localhost:8080/hello-world",
    "https://jacks-spring-micro-api-site.herokuapp.com/hello-world",
    {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-api-key": "111777",
      },
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json(); // .json() will parse the response body of the response.  In this case grabbing the employee object.
    })
    .catch((error) => {
      console.error(
        "There was a problem with the hello world fetch operation",
        error
      );
    });
}

export async function getMicroServiceHelloWorldService() {
  const herokuTestData = await getMicroServiceHelloWorldData();
  return herokuTestData;
}

// OPENWEATHERMAP.ORG
async function getWeatherMapOrgDataForCityBaseUrl(city: any) {
  return await fetch(
    `http://localhost:9000/.netlify/functions/api/weather-by-state?q=${city}`,
    {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  )
    .then((body) => body)
    .then((res) => {
      return res.json();
    });
}

export async function getWeatherMapOrgDataForCityService(city: any) {
  const weatherData = await getWeatherMapOrgDataForCityBaseUrl(city);
  // console.log('weatherData')
  // console.log(weatherData)
  return weatherData;
}

async function getMicroServiceAllEmployeesData() {
  return await fetch(
    // "http://localhost:8080/hello-world",
    "https://jacks-spring-micro-api-site.herokuapp.com/api/v1/employees",
    {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-api-key": "111777",
      },
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json(); // .json() will parse the response body of the response.  In this case grabbing the employee object.
    })
    .catch((error) => {
      console.error(
        "There was a problem with the hello world fetch operation",
        error
      );
    });
}

export async function getMicroServiceAllEmployeesService() {
  const allEmployees = await getMicroServiceAllEmployeesData();
  return allEmployees;
}

async function getExpressAllEmployeesData() {
  return await fetch(
    "http://localhost:9000/.netlify/functions/api/micro-service/employees",
    // "https://www.whatbackground.com/.netlify/functions/api/micro-service/employees",
    {
      method: "get",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "x-api-key": "111777",
      },
    }
  )
    .then((response) => {
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return response.json(); // .json() will parse the response body of the response.  In this case grabbing the employee object.
    })
    .catch((error) => {
      console.error(
        "There was a problem with the hello world fetch operation",
        error
      );
    });
}

export async function getExpressAllEmployeesService() {
  const allEmployees = await getExpressAllEmployeesData();
  return allEmployees;
}

// *********************************************************

async function postWeddingInquiryBaseUrl(weddingInquiryObj: any) {
  console.log("postWeddingInquiryBaseUrl");
  console.log("object made it to the baseurl call: ", weddingInquiryObj);
  const { fullName, email, note } = weddingInquiryObj;

  return await fetch(
    "http://localhost:9000/.netlify/functions/api/micro-service/wedding-inquiries",
    {
      method: "post",
      headers: {
        "content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(weddingInquiryObj),
    }
  )
    .then((response) => response)
    .then((response) => {
      if (response)
        if (response.ok) {
          const data = response.json();
          // Handle success response
          return data;
        } else {
          // Handle error response
          throw new Error("API request failed");
        }
    })
    .catch((error) => {
      // Handle any other errors
      console.error(error);
      throw error;
    });
}

async function postWeddingInquiry(weddingInquiryObj: any) {
  console.log("postWeddingInquiry");
  const urlResponse = await postWeddingInquiryBaseUrl(weddingInquiryObj);
  // console.log('weatherData')
  // console.log(weatherData)
  return urlResponse;
  // const apiUrl = `http://localhost:9000/.netlify/functions/api/wedding-inquiry`;

  // const weddingInquiryInformation = {
  //   fullName: "Bob",
  //   email: "bob@email.com",
  //   note: "my notes",
  // };

  // try {
  //   const response = await fetch(apiUrl, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       "Access-Control-Allow-Origin": "*",
  //     },
  //     body: JSON.stringify(weddingInquiryInformation),
  //   });

  //   if (response.ok) {
  //     console.log("You posted wedding inquiry!");
  //   } else {
  //     console.error(
  //       "Hit API to post but failed to post wedding inquiry. Status:",
  //       response.status
  //     );
  //   }
  // } catch (error) {
  //   console.error("Can't even post wedding inquiry to api", error);
  // }
}

export async function postWeddingInquiryService(weddingInquiryObj: any) {
  console.log("postWeddingInquiryService");
  const urlResponse = await postWeddingInquiry(weddingInquiryObj);
  return urlResponse;
}
