import { reactive, readonly } from "@vue/reactivity";

import { Poop } from "../types/TestTypes";
import { ProxyServerTestType } from "../types/TestTypes";

import {
  getTestService,
  getMicroServiceHelloWorldService,
  getWeatherMapOrgDataForCityService,
  getMicroServiceAllEmployeesService,
  getExpressAllEmployeesService,
  postWeddingInquiryService,
} from "../services/OldService";

const defaultState = {
  proxyServerIsUpTest: {
    bigtester: "nothing",
  } as ProxyServerTestType,
  weatherByState: "nothing",
  stupidObject: {} as Poop,
  herokuTestData: "" as any,
  allEmployees: "" as any,
  allEmployeesWhatBackground: "" as any,
};

const state = reactive(defaultState);

const getters = {
  proxyServerIsUpTest: (): ProxyServerTestType => state.proxyServerIsUpTest,
  stateMyWeatherForCity: () => state.weatherByState,
  stupidObject: (): Poop => state.stupidObject,
  herokuTestData: (): any => state.herokuTestData,
  allEmployees: (): any => state.allEmployees,
  allEmployeesWhatBackground: (): any => state.allEmployeesWhatBackground,
};

const mutations = {
  SET_PROXY_SERVER_IS_UP_TEST: (payload: ProxyServerTestType): void => {
    state.proxyServerIsUpTest = payload;
  },
  SET_WEATHER_BY_CITY: (payload: any) => {
    state.weatherByState = payload;
  },
  SET_STUPID_OBJECT: (payload: Poop): void => {
    state.stupidObject = payload;
  },
  SET_HEROKU_TEST_DATA: (test: any): void => {
    state.herokuTestData = test;
  },
  SET_ALL_EMPLOYEES: (test: any): void => {
    state.allEmployees = test;
  },
  SET_ALL_EMPLOYEES_WHAT_BACKGROUND: (test: any): void => {
    state.allEmployeesWhatBackground = test;
  },
};

const actions = {
  fetchFromProxyServerTest: async (): Promise<void> => {
    try {
      const response: ProxyServerTestType = await getTestService();

      mutations.SET_PROXY_SERVER_IS_UP_TEST(response);
    } catch (e) {
      //   throw new Error(e);
      throw new Error("blah");
    }
  },
  fetchHerokuTestData: async (): Promise<void> => {
    try {
      const response: any = await getMicroServiceHelloWorldService();
      console.log(response);
      mutations.SET_HEROKU_TEST_DATA(response);
    } catch (e) {
      //   throw new Error(e);
      throw new Error("blah");
    }
  },
  fetchWeatherMapOrgDataForCity: async (test: any): Promise<void> => {
    try {
      const response = await getWeatherMapOrgDataForCityService(test);

      response.main.temp = kelvinToFahrenheit(response.main.temp);
      mutations.SET_WEATHER_BY_CITY(response);
    } catch (e) {
      //   throw new Error(e);
      throw new Error("blah");
    }
  },
  fetchAllEmployees: async (): Promise<void> => {
    try {
      const response: any = await getMicroServiceAllEmployeesService();
      console.log(response);
      mutations.SET_ALL_EMPLOYEES(response);
    } catch (e) {
      //   throw new Error(e);
      throw new Error("blah");
    }
  },
  fetchAllEmployeesWhatBackground: async (): Promise<void> => {
    try {
      const response: any = await getExpressAllEmployeesService();
      console.log(response);
      mutations.SET_ALL_EMPLOYEES(response);
    } catch (e) {
      //   throw new Error(e);
      throw new Error("blah");
    }
  },
  postWeddingInquiry: async (weddingInquiryObj: any): Promise<void> => {
    try {
      const response: any = await postWeddingInquiryService(weddingInquiryObj);
      //   mutations.SET_ALL_EMPLOYEES(response);
    } catch (e) {
      //   throw new Error(e);
      //   throw new Error("blah");
      console.log("THIS SUCKS");
    }
  },
};

// Convert Kelvin to Fahrenheit
const kelvinToFahrenheit = (temp: any) => {
  return Math.ceil(((temp - 273.15) * 9) / 5 + 32);
};

export default {
  state: readonly(state),
  getters: readonly(getters),
  actions: readonly(actions),
  mutations: readonly(mutations),
};
