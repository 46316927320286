
import { defineComponent } from "vue";

import MusicPlayer from "@/components/MusicPlayer.vue";

import { Howl } from "howler";

export default defineComponent({
  name: "CelloStudio",
  components: { MusicPlayer },
});
