import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CelloStudio from "../views/CelloStudio.vue";
import RepertoireView from "../views/RepertoireView.vue";
import VueThreeApps from "../views/VueThreeApps.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "homeView",
    component: HomeView,
  },
  {
    path: "/cello-studio/:piece?",
    name: "CelloStudio",
    component: CelloStudio,
    props: {
      msg: "This value was passed from routers index.js and is a random property, you should use query strings instead",
    },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/hire-me",
    name: "weddings",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WeddingInquiry.vue"),
  },
  {
    path: "/repertoire",
    name: "RepertoireView",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/RepertoireView.vue"),
  },
  {
    path: "/old-apps",
    name: "VueThreeApps",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: VueThreeApps,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

export default router;
