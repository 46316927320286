
import { computed, defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "HomeView",
  components: {},
  props: {
    testValue: String,
    testValueTwo: { type: Number, required: false },
  },
  emits: ["emitOne", "emitTwo"],
  setup(props, context) {
    // DATA
    // example
    // const localCurrentPage = ref(props.currentPage);
    // const isTrueSomething = ref(false) --> isTrueSomething.value = false;
    const watchMe = ref(100);
    const stupidValue = ref(200);

    // COMPUTED
    const page = computed(() => {
      const foo = 100;
      return foo;
    });

    // METHODS
    const testerOne = function () {
      return 100;
    };
    const testerTwo = function () {
      return 200;
    };
    const testThree = () => {
      const myArr = [1, 2, 3];
      return myArr.slice(0, 1);
    };

    const calculateSomething = 100 * 100;

    const emitSomething = () => {
      stupidValue.value = 400;
      context.emit("emitOne", stupidValue.value);
    };

    // WATCHERS
    watch(watchMe, () => {
      stupidValue.value = 300;
    });

    return {
      testerOne,
      testerTwo,
      testThree,
      emitSomething,
      calculateSomething,
    };
  },
});
