<template>
  <div class="music-player container">
    <div class="listen font-extrabold text-4xl mb-4">Recordings</div>
    <div class="flex flex-col md:flex-row">
      <div class="md:w-1/2"></div>
      <div class="md:w-1/2">
        <input
          type="text"
          placeholder="Search By Title or Composer ..."
          class="w-[300px] md:w-1/2 md:m-4 border border-gray-300 rounded-sm px-4 py-2 focus:outline-none bg-white"
          v-model="searchTerm"
          @input="debouncedSearch"
        />
        <i
          v-if="searchTerm"
          @click="clearSearchInput"
          class="fas fa-times fa-md cursor-pointer ml-2 md:ml-0"
        ></i>
      </div>
    </div>
    <div class="flex flex-col md:flex-row">
      <div class="flex items-center justify-center flex-col md:w-1/2 h-[425px]">
        <div
          v-if="imageClass"
          :class="imageClass"
          class="grid music-media-image-container h-[320px] w-[320px]"
        >
          <div class="flex flex-col justify-between items-center">
            <div></div>
            <div
              v-if="selectedTrack > -1"
              class="circle-background cursor-pointer mt-2 flex flex-col items-center justify-center"
            >
              <i
                v-if="selectedTrack > -1 && isPlaying === false"
                class="image-play-icon fas fa-play fa-lg ml-1 text-white"
                @click="resumeTrack()"
              ></i>
              <i
                v-if="selectedTrack > -1 && isPlaying === true"
                class="image-pause-icon fas fa-pause fa-lg ml-[0.5] text-white"
                @click="pauseTrack(selectedTrack)"
              ></i>
            </div>
            <div v-if="progressBarWidth" class="progress-container flex-grow-0">
              <!-- <span class="fixed">{{ playDuration }}/{{ trackDuration }}</span> -->
              <div
                :style="{ width: progressBarWidth }"
                class="progress-bar"
              ></div>
            </div>
          </div>
        </div>
        <div
          v-else
          class="grid music-media-image-container h-[320px] w-[320px] items-center"
        >
          <i class="fas fa-microphone-slash fa-lg ml-[0.5]"></i>
        </div>
      </div>
      <!-- <div>{{ filteredTracks }}</div> -->
      <div class="flex flex-col md:w-1/2 h-[420px] overflow-y-auto">
        <ul
          v-for="(track, idx) in filteredTracks"
          :key="track.id"
          class="tracks-table"
          @click="playTrack(track, idx)"
        >
          <li
            class="track custom-hover cursor-pointer flex flex-row mt-4 mx-4"
            :class="{ 'active-track-color': selectedTrack === idx }"
          >
            <transition
              name="slide"
              enter-active-class="transition duration-650 transform ease-out"
              leave-active-class="transition duration-650 transform ease-in"
            >
              <div
                v-if="showSelectedTrack(idx)"
                class="track-play-col flex slide items-center px-4"
              >
                <i class="play-icon fa-sharp fas fa-play fa-xs"></i>
              </div>
            </transition>
            <div class="flex flex-col items-start justify-center ml-4">
              <div class="track-text flex-shrink-0 text-sm tracking-wide">
                {{ track.title }}
              </div>
              <div
                class="composer-text flex-shrink-0 text-xs font-light tracking-wide"
              >
                {{ track.composer }}
                <span
                  v-if="track.isNewest"
                  class="ml-2 bg-green-400 rounded-md px-1 py-[.5px] text-white text-xs"
                  >Newest</span
                >
                <span
                  v-if="track.isRevised"
                  class="ml-2 bg-yellow-300 rounded-md px-1 py-[.5px] text-black text-xs"
                  >Re-Recorded</span
                >
                <span
                  v-if="track.mostPopular"
                  class="ml-2 bg-orange-400 rounded-md px-1 py-[.5px] text-white text-xs"
                  >Most Popular</span
                >
                <span
                  v-if="track.isEvangelion"
                  class="ml-2 bg-purple-400 rounded-md px-1 py-[.5px] text-white text-xs"
                  >Evangelion</span
                >
                <span
                  v-if="track.isPianoOnly"
                  class="ml-2 bg-sky-500 rounded-md px-1 py-[.5px] text-white text-xs"
                  >Piano Only</span
                >
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  mounted,
  onMounted,
  ref,
} from "vue";
import { useRoute } from "vue-router";

import { Howl } from "howler";
import { debounce } from "lodash/debounce";

// Romance Pieces
import BachAveMaria from "@/assets/bach_ave_maria.mp3";
import BachGMajorPreludeShinjiIkari from "@/assets/bach_g_major_prelude_shinji_ikari.mp3";
import ChopinCelloSonataLargo from "@/assets/chopin_cello_sonata_largo.mp3";
import ElgarSalutdAmour from "@/assets/elgar_salut_damour.mp3";
import FaureApresUnReve from "@/assets/faure_apres_un_reve.mp3";
// import RachCelloSonataAndante from "@/assets/rach_cello_sonata_andante.wav";
import SaintSaensTheSwan from "@/assets/saint_saens_the_swan_passion.mp3";

// Bach Cello Suites
import BachGMajorGigue from "@/assets/bach_cello_suite_1_gigue.mp3";
import BachGMajorSarabande from "@/assets/bach_cello_suite_1_sarabande.mp3";
import BachCMajorBourree from "@/assets/bach_cello_suite_3_bourree.mp3";

// Piano Only Pieces like Chopin Preludes - Probably other pieces too - 12
import ChopinPreludesOp28No7 from "@/assets/chopin_prelude_28_7.mp3";

export default defineComponent({
  name: "MusicPlayer",
  components: {},
  props: {
    msg: String,
  },
  setup() {
    // DATA
    const hovered = ref(false);
    const selectedTrack = ref(-1);
    const progressBarWidth = ref(0);
    const isPlaying = ref(false);
    // new
    const progress = 0;
    const howl = null;
    const progressInterval = null;

    const searchTerm = ref("");
    // this
    const progressLoop = ref(0);

    let sound;
    const trackDuration = ref(0);
    const playDuration = ref(0);

    const imageClass = ref("");

    // tracks
    const mediaTracks = [
      // {
      //   id: 10,
      //   title: "Chopin Prelude Op. 28 No. 7 in A major",
      //   composer: "Frederic Chopin",
      //   tags: "Bach, classical",
      //   imageClass: "bg-image4",
      //   isNewest: true,
      //   mostPopular: false,
      //   isRevised: false,
      //   isPianoOnly: true,
      // },
      // {
      //   id: 11,
      //   title: "Rachmaninoff Cello Sonata - Andante",
      //   composer: "Rachmaninoff, Sergei",
      //   tags: "Bach, classical",
      //   imageClass: "alone-i-sail",
      //   isNewest: true,
      //   mostPopular: false,
      //   isRevised: false,
      //   isPianoOnly: false,
      // },
      {
        id: 2,
        title: "The Swan",
        composer: "Saint-Saens",
        tags: "Bach, classical",
        imageClass: "album-cover-park-swan-donna-bingaman",
        isNewest: false,
        mostPopular: false,
        isRevised: false,
        isPianoOnly: false,
      },
      {
        id: 1,
        title: "Salut d'Amour",
        composer: "Edward Elgar",
        tags: "Bach, classical",
        imageClass: "love-lonely-rose",
        isNewest: false,
        mostPopular: false,
        isRevised: false,
        isPianoOnly: false,
      },
      {
        id: 4,
        title:
          "Bach Cello Suite 1 in G Major - Prelude - Death & Rebirth - Shinji Ikari Ver.",
        composer: "J.S. Bach",
        tags: "Bach, classical",
        imageClass: "bg-image1",
        isNewest: false,
        mostPopular: false,
        isEvangelion: true,
        isRevised: false,
        isPianoOnly: false,
      },
      {
        id: 3,
        title: "Apres Un Reve",
        composer: "Gabriel Faure",
        tags: "Bach, classical",
        imageClass: "bg-image3",
        isNewest: false,
        mostPopular: false,
        isRevised: false,
        isPianoOnly: false,
      },
      {
        id: 5,
        title: "Chopin Cello Sonata in G Minor - Largo",
        composer: "Frederic Chopin",
        tags: "Classical, Chopin",
        imageClass: "bg-image4",
        isNewest: false,
        mostPopular: false,
        isRevised: false,
        isPianoOnly: false,
      },
      {
        id: 6,
        title: "Ave Maria",
        composer: "J.S. Bach",
        imageClass: "bg-image5",
        isNewest: false,
        mostPopular: false,
        isRevised: false,
        isPianoOnly: false,
      },
      {
        id: 7,
        title: "Bach Cello Suite 1 in G Major - Gigue",
        composer: "J.S. Bach",
        imageClass: "bg-image2",
        isNewest: false,
        mostPopular: false,
        isRevised: false,
        isPianoOnly: false,
      },
      {
        id: 8,
        title: "Bach Cello Suite 1 in G Major - Sarabande",
        composer: "J.S. Bach",
        imageClass: "bg-image2",
        isNewest: false,
        mostPopular: false,
        isRevised: false,
        isPianoOnly: false,
      },
      {
        id: 9,
        title: "Bach Cello Suite 3 in C Major - Bourree",
        composer: "J.S. Bach",
        imageClass: "bg-image2",
        isNewest: false,
        mostPopular: false,
        isRevised: false,
        isPianoOnly: false,
      },
    ];

    // METHODS

    const updatePlayDuration = () => {
      setInterval(() => {
        if (isPlaying.value) {
          const durationInSeconds = Math.floor(sound.seek());
          playDuration.value = formatTime(durationInSeconds);
        }
      }, 1000);
    };

    const playTrack = (track, indexOfTrack) => {
      let currentState = "unknown";

      if (sound) {
        currentState = sound.state();
      }

      // Fixes bug where a user might click the track multiple times quickly
      if (currentState === "loading") {
        resetAllSoundAndProgress();
        return;
      }

      if (currentState === "loaded") {
        resetAllSoundAndProgress();
        return;
      }

      if (track.id === 1) {
        howlSetup(track, indexOfTrack, ElgarSalutdAmour);
      }

      if (track.id === 2) {
        howlSetup(track, indexOfTrack, SaintSaensTheSwan);
      }

      if (track.id === 3) {
        howlSetup(track, indexOfTrack, FaureApresUnReve);
      }

      if (track.id === 4) {
        howlSetup(track, indexOfTrack, BachGMajorPreludeShinjiIkari);
      }

      if (track.id == 5) {
        howlSetup(track, indexOfTrack, ChopinCelloSonataLargo);
      }

      if (track.id == 6) {
        howlSetup(track, indexOfTrack, BachAveMaria);
      }

      if (track.id == 7) {
        howlSetup(track, indexOfTrack, BachGMajorGigue);
      }

      if (track.id == 8) {
        howlSetup(track, indexOfTrack, BachGMajorSarabande);
      }

      if (track.id == 9) {
        howlSetup(track, indexOfTrack, BachCMajorBourree);
      }

      // if (track.id == 10) {
      //   howlSetup(track, indexOfTrack, ChopinPreludesOp28No7);
      // }

      // if (track.id == 11) {
      //   howlSetup(track, indexOfTrack, RachCelloSonataAndante);
      // }
    };

    const howlSetup = (track, indexOfTrack, srcName) => {
      // sound.stop();
      // sound.unload();
      sound = new Howl({
        src: [srcName],
        html5: false,
        onload: () => {
          const durationInSeconds = Math.round(sound.duration());
          trackDuration.value = formatTime(durationInSeconds);
        },
        onend: onEndCallback,
      });

      // set the tracks album cover
      imageClass.value = track.imageClass;

      startProgress(sound);

      selectedTrack.value = indexOfTrack;

      updatePlayDuration(sound);

      sound.play();
      isPlaying.value = true;
    };

    const pauseTrack = () => {
      sound.pause();
      isPlaying.value = false;
      // console.log("selected Track is ", selectedTrack);
    };

    const resumeTrack = () => {
      sound.play();
      isPlaying.value = true;
    };

    const showSelectedTrack = (idx) => {
      if (selectedTrack.value === idx) {
        return true;
      }
    };

    const startProgress = (sound) => {
      progressLoop.value = setInterval(() => {
        const seek = sound.seek() || 0;
        const duration = sound.duration() || 1;
        progressBarWidth.value = `${(seek / duration) * 100}%`;
      }, 100);
    };

    const stopProgress = () => {
      // // Stop the progress update loop
      clearInterval(progressLoop.value);
      progressBarWidth.value = 0;
    };

    const resetAllSoundAndProgress = () => {
      // prevent sound is undefined error
      if (sound) {
        sound.stop();
        sound.unload();
      }
      stopProgress();

      // remove album cover
      imageClass.value = "";

      progressBarWidth.value = 0;

      selectedTrack.value = -1;
      isPlaying.value = false;
    };

    const clearSearchInput = () => {
      resetAllSoundAndProgress();
      searchTerm.value = "";
    };

    // Function to format the duration as minutes:seconds
    function formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(remainingSeconds).padStart(2, "0");
      return `${formattedMinutes}:${formattedSeconds}`;
    }

    const debouncedSearch = () => {
      // console.log("is debounce even running?");
      resetAllSoundAndProgress();
      // debounce(function () {
      //   // This method is debounced by 500ms
      //   // You can perform any additional logic here if needed
      //   // J - Decided not to use it
      //   resetAllSoundAndProgress();
      // }, 1);
    };

    // COMPUTED
    const filteredTracks = computed(() => {
      // just reset the sound and media player when they start searching to avoid bugs
      if (searchTerm.value === "") {
        return mediaTracks;
      }

      const searchTermLower = searchTerm.value.toLowerCase();
      return mediaTracks.filter(
        (track) =>
          track.title.toLowerCase().includes(searchTermLower) ||
          track.composer.toLowerCase().includes(searchTermLower)
      );
    });

    // CALLBACKS
    const onEndCallback = () => {
      // remove album cover
      imageClass.value = "";
      selectedTrack.value = -1;
      isPlaying.value = false;
    };

    // ON MOUNT
    onMounted(() => {
      const route = useRoute();
      const value = route.query.piece;

      sound = null;

      if (route.query.piece) {
        // console.log("route piece found");
        searchTerm.value = route.query.piece;
      }
    });

    onBeforeUnmount(() => {
      // Stop the sound and release resources
      if (sound) {
        sound.stop();
        sound.unload();
      }
    });

    return {
      imageClass,
      clearSearchInput,
      debouncedSearch,
      searchTerm,
      filteredTracks,
      showSelectedTrack,
      selectedTrack,
      mediaTracks,
      progressBarWidth,
      playTrack,
      pauseTrack,
      resumeTrack,
      isPlaying,
      trackDuration,
      playDuration,
      hovered,
    };
  },
});
</script>

<style scoped lang="scss">
.music-player {
  .listen {
    font-family: "Gelasio";
    color: #444;
  }
  .music-media-image-container {
    background-color: #e9e9e9;
    // background-image: url("~@/assets/eight-string-religion.jpg");
    // background-position: center bottom;
    // background-repeat: no-repeat;
    // background-attachment: fixed;
    // background-size: 100%;
    // background-color: #999;

    // background-image: url("~@/assets/eight-string-religion.jpg");
    /* Set the path to your image */
    background-size: 100%;
    /* Make the image fit the size of the div */
    background-position: center;

    .circle-background {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #76d2f6e6;
    }

    .progress-container {
      width: 100%;
      height: 20px;
      background-color: #f0f0f0;
      // border-radius: 5px;
      overflow: hidden;
    }

    .progress-bar {
      height: 100%;
      background-color: #76d2f6e6;
      transition: width 0.3s;
    }
  }

  .tracks-table {
    .custom-hover:hover {
      background-color: #e9e9e9;
      /* Replace with your desired hover color */
    }

    .active-track-color {
      background-color: #e9e9e9 !important;
    }

    .track {
      background-color: #f1f1f1;
      min-height: 65px;
      font-family: "Montserrat", "sans-serif";

      .hide-me {
        display: none;
      }

      .play-icon {
        color: #666;
      }

      .composer-text {
        color: #999;
      }

      .slide-enter-from {
        opacity: 0;
        transform: translateX(50%);
      }

      .slide-enter-to {
        opacity: 1;
        transform: translateX(0);
      }

      .slide-leave-from {
        opacity: 1;
        transform: translateX(0);
      }

      .slide-leave-to {
        opacity: 0;
        transform: translateX(50%);
      }
    }
  }

  .bg-image1 {
    background-image: url("~@/assets/nobody_told_me_to_stop.png");
    background-repeat: no-repeat;
  }
  .bg-image2 {
    background-image: url("~@/assets/albumcovers/bach_cello_suites.png");
    background-repeat: no-repeat;
  }

  .bg-image3 {
    background-image: url("~@/assets/albumcovers/faure_apres_album.png");
    background-repeat: no-repeat;
  }

  .bg-image4 {
    background-image: url("~@/assets/albumcovers/chopin_largo_album.png");
    background-repeat: no-repeat;
  }

  .bg-image5 {
    background-image: url("~@/assets/albumcovers/bach_ave_maria.png");
    background-repeat: no-repeat;
  }

  .album-cover-park-swan-donna-bingaman {
    background-image: url("@/assets/albumcovers/album-cover-park-swan-donna-bingaman.png");
    background-repeat: no-repeat;
  }

  .love-lonely-rose {
    background-image: url("@/assets/albumcovers/lovely_lonely_rose.png");
    background-repeat: no-repeat;
  }

  .alone-i-sail {
    background-image: url("~@/assets/albumcovers/alone_i_sail.png");
    background-repeat: no-repeat;
  }
}
</style>
